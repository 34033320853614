<template>
  <div id="student-info">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <!-- Basic -->
      <b-collapse
        class="card"
        animation="slide"
        :open="index == activeTab"
        @open="setOpen(0)"
      >
        <div
          class="card-header"
          role="button"
          slot="trigger"
          slot-scope="props"
        >
          <p class="card-header-title">Basic</p>
          <a class="card-header-icon">
            <b-icon :icon="props.open ? 'arrow-up' : 'arrow-down'"> </b-icon>
          </a>
        </div>
        <div class="card-content">
          <div class="content">
            <ValidationObserver ref="observer" v-slot="{ passes }">
              <div class="columns">
                <div class="column is-6 is-offset-3">
                  <form @submit.prevent="passes(updateSchoolBasicInfo)">
                    <SdInput
                      type="text"
                      rules="required"
                      label="Name"
                      v-model="school.name"
                    />

                    <SdInput
                      type="text"
                      rules="required"
                      label="Email"
                      v-model="school.email"
                    />

                    <SdInput
                      type="text"
                      rules="required"
                      label="Address 1"
                      v-model="school.address1"
                    />

                    <SdInput
                      type="text"
                      rules="required"
                      label="Address 2"
                      v-model="school.address2"
                    />

                    <SdInput
                      type="text"
                      rules="required"
                      label="Address 3"
                      v-model="school.address3"
                    />

                    <SdInput
                      type="text"
                      rules="required"
                      label="Phone 1"
                      v-model="school.phone1"
                    />

                    <SdInput
                      type="text"
                      rules="required"
                      label="Phone 2"
                      v-model="school.phone2"
                    />

                    <SdSelect
                      type="text"
                      label="Classification"
                      v-model="school.classification"
                    >
                      <option
                        v-for="classification in classifications"
                        :key="classification.name"
                      >
                        {{ classification.name }}
                      </option>
                    </SdSelect>

                    <SdInput
                      type="text"
                      rules="required"
                      label="Abbrv"
                      v-model="school.abbrv"
                    />

                    <button
                      type="submit"
                      class="button btn-120 is-primary is-capitalized is-pulled-right mt-4"
                    >
                      Save
                    </button>
                  </form>
                </div>
              </div>
            </ValidationObserver>
          </div>
        </div>
      </b-collapse>
      <!-- School Logo -->
      <b-collapse
        class="card"
        animation="slide"
        :open="index == 1"
        @open="setOpen(1)"
      >
        <div
          class="card-header"
          role="button"
          slot="trigger"
          slot-scope="props"
        >
          <p class="card-header-title">School Logo</p>
          <a class="card-header-icon">
            <b-icon :icon="props.open ? 'arrow-up' : 'arrow-down'"> </b-icon>
          </a>
        </div>
        <div class="card-content">
          <div class="content">
            <div class="has-text-centered">
              <figure class="image is-256x256 is-inline-block" width="400px">
                <img v-if="school.imgaeUrl" :src="school.imageUrl" />
                <img v-else :src="school.imageUrl" />
              </figure>
            </div>
            <br />
            <div class="has-text-centered">
              <a class="button btn-120 is-primary" @click="uploadImage()"
                >Change</a
              >
            </div>
            <ImageModal
              :subject="imageSubject"
              :data="{ id: 'school_logo', url: school.imageUrl }"
              :is-data-url="true"
              :show-modal.sync="modal"
              @create-upload="updateUpload"
              @update-upload="updateUpload"
            />
          </div>
        </div>
      </b-collapse>
      <!-- Simsed Category -->
      <b-collapse
        class="card"
        animation="slide"
        :open="index == 2"
        @open="setOpen(2)"
      >
        <div
          class="card-header"
          role="button"
          slot="trigger"
          slot-scope="props"
        >
          <p class="card-header-title">Simsed Category</p>
          <a class="card-header-icon">
            <b-icon :icon="props.open ? 'arrow-up' : 'arrow-down'"> </b-icon>
          </a>
        </div>
        <div class="card-content">
          <div class="content">
            <ValidationObserver ref="observer" v-slot="{ passes }">
              <div class="columns">
                <div class="column is-6 is-offset-3">
                  <form @submit.prevent="passes(updateSimsedCategory)">
                    <SdSelect
                      type="text"
                      label="Simsed Category"
                      v-model="school.simsedCategory"
                    >
                      <option
                        v-for="category in categories"
                        :key="category.name"
                      >
                        {{ category.name }}
                      </option>
                    </SdSelect>

                    <SdInput
                      type="text"
                      rules="required"
                      label="Subscription Fee"
                      v-model="school.subscriptionFee"
                      disabled
                    />

                    <button
                      type="submit"
                      class="button btn-120 is-primary is-capitalized is-pulled-right mt-4"
                    >
                      Save
                    </button>
                  </form>
                </div>
              </div>
            </ValidationObserver>
          </div>
        </div>
      </b-collapse>
      <!-- Add-On Modules -->
      <b-collapse
        class="card"
        animation="slide"
        :open="index == 3"
        @open="setOpen(3)"
      >
        <div
          class="card-header"
          role="button"
          slot="trigger"
          slot-scope="props"
        >
          <p class="card-header-title">Add-On Modules</p>
          <a class="card-header-icon">
            <b-icon :icon="props.open ? 'arrow-up' : 'arrow-down'"> </b-icon>
          </a>
        </div>
        <div class="card-content">
          <div class="columns">
            <div class="column is-6 is-offset-3">
              <div class="field">
                <b-switch
                  v-model="school.feesEnabled"
                  type="is-success"
                  :disabled="disableFees"
                  >School Fee Management</b-switch
                >
              </div>
              <div class="field">
                <b-switch
                  v-model="school.eLearningEnabled"
                  type="is-success"
                  :disabled="disableELearning"
                  >E Learning</b-switch
                >
              </div>
              <div class="field">
                <b-switch
                  v-model="school.hrEnabled"
                  type="is-success"
                  :disabled="disableHr"
                  >Personnel Management</b-switch
                >
              </div>
              <div class="field">
                <b-switch
                  v-model="school.accountingEnabled"
                  type="is-success"
                  :disabled="disableFinance"
                  >Financial Management</b-switch
                >
              </div>
              <button
                type="submit"
                class="button btn-120 is-primary is-capitalized is-pulled-right mt-4"
                @click="updateAddOnModules"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </b-collapse>
      <!-- Settings -->
      <b-collapse
        class="card"
        animation="slide"
        :open="index == 6"
        @open="setOpen(6)"
      >
        <div
          class="card-header"
          role="button"
          slot="trigger"
          slot-scope="props"
        >
          <p class="card-header-title">Result Settings</p>
          <a class="card-header-icon">
            <b-icon :icon="props.open ? 'arrow-up' : 'arrow-down'"> </b-icon>
          </a>
        </div>
        <div class="card-content">
          <div class="content">
            <ValidationObserver ref="observer" v-slot="{ passes }">
              <div class="columns">
                <div class="column is-6 is-offset-3">
                  <form @submit.prevent="passes(updateSettings)">
                    <div class="field mt-4">
                      <b-switch
                        v-model="school.hideResultHeader"
                        type="is-success"
                        :disabled="disableResultHeader"
                        >Hide Result Header</b-switch
                      >
                    </div>

                    <div class="field mt-4">
                      <b-switch
                        v-model="school.resultAvatarEnabled"
                        type="is-success"
                        :disabled="disableResultAvatar"
                        >Display student's picture on result</b-switch
                      >
                    </div>

                    <div class="field mt-4">
                      <b-switch
                        v-model="school.displayFee"
                        type="is-success"
                        :disabled="disableDisplayFee"
                        >Display School Fees on result</b-switch
                      >
                    </div>

                    <button
                      type="submit"
                      class="button btn-120 is-primary is-capitalized is-pulled-right mt-4"
                    >
                      Save
                    </button>
                  </form>
                </div>
              </div>
            </ValidationObserver>
          </div>
        </div>
      </b-collapse>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { crudNotification } from '@/assets/js/notification'
import { fetchUser } from '@/assets/js/app_info.js'
import SdInput from '../../components/SdInput'
import SdSelect from '../../components/SdSelect'
import { ValidationObserver } from 'vee-validate'
import ImageModal from '../../components/ImageModal.vue'

export default {
  data() {
    return {
      url: null,
      user: null,
      schoolId: null,
      pageTitle: 'School',
      school: {},
      schoolCopy: {},
      activeTab: 0,
      isOpen: 0,
      index: 0,
      disableFees: false,
      disableELearning: false,
      disableGSuite: false,
      disableHr: false,
      disableFinance: false,
      disableResultHeader: true,
      disableResultAvatar: true,
      disableDisplayFee: true,
      classifications: [
        {
          name: 'Nursery',
        },
        {
          name: 'Primary',
        },
        {
          name: 'Secondary',
        },
        {
          name: 'Nursery and Primary',
        },
        {
          name: 'Primary and Secondary',
        },
        {
          name: 'Nursery, Primary and Secondary',
        },
      ],
      categories: [
        {
          name: 'NOVO',
        },
        {
          name: 'PRIME',
        },
        {
          name: 'MASTER',
        },
      ],
      modal: false,
      imageSubject: 'school_logo',
    }
  },
  watch: {
    school(data) {
      this.schoolCopy = JSON.parse(JSON.stringify(data))
    },
    'school.simsedCategory'(value) {
      if (value === 'NOVO') {
        this.school.subscriptionFee = '200.00'

        this.school.feesEnabled = this.schoolCopy.feesEnabled
        this.school.eLearningEnabled = false
        this.school.gSuiteEnabled = false
        this.school.hrEnabled = false
        this.school.accountingEnabled = false

        this.disableELearning = true
        this.disableHr = true
        this.disableFinance = true

        this.school.hideResultHeader = false
        this.school.resultAvatarEnabled = false
        this.school.displayFee = false

        this.disableResultHeader = true
        this.disableResultAvatar = true
        this.disableDisplayFee = true
      } else if (value === 'PRIME') {
        this.school.subscriptionFee = '400.00'

        this.school.feesEnabled = this.schoolCopy.feesEnabled
        this.school.eLearningEnabled = this.schoolCopy.eLearningEnabled
        this.school.gSuiteEnabled = this.schoolCopy.gSuiteEnabled
        this.school.hrEnabled = false
        this.school.accountingEnabled = false

        this.disableFees = false
        this.disableELearning = false
        this.disableHr = true
        this.disableFinance = true

        this.school.hideResultHeader = this.schoolCopy.hideResultHeader
        this.school.resultAvatarEnabled = this.schoolCopy.resultAvatarEnabled
        this.school.displayFee = this.schoolCopy.displayFee

        this.disableResultHeader = false
        this.disableResultAvatar = false
        this.disableDisplayFee = false
      } else if (value === 'MASTER') {
        this.school.subscriptionFee = '1000.00'

        this.school.feesEnabled = this.schoolCopy.feesEnabled
        this.school.eLearningEnabled = this.schoolCopy.eLearningEnabled
        this.school.gSuiteEnabled = this.schoolCopy.gSuiteEnabled
        this.school.hrEnabled = this.schoolCopy.hrEnabled
        this.school.accountingEnabled = this.schoolCopy.accountingEnabled

        this.disableFees = false
        this.disableELearning = false
        this.disableHr = false
        this.disableFinance = false

        this.school.hideResultHeader = this.schoolCopy.hideResultHeader
        this.school.resultAvatarEnabled = this.schoolCopy.resultAvatarEnabled
        this.school.displayFee = this.schoolCopy.displayFee

        this.disableResultHeader = false
        this.disableResultAvatar = false
        this.disableDisplayFee = false
      }
    },
  },
  methods: {
    // For image modal
    uploadImage() {
      this.modal = true
    },
    updateUpload(data) {
      this.updateSchoolLogo(data.dataUrl)
    },
    updateSchoolLogo(imageUrl) {
      // Update School Logo
      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateSchoolLogoQuery($id: ID!, $image_url: String!) {
              updateSchoolLogo(input: { id: $id, imageUrl: $image_url }) {
                school {
                  id
                }
                errors
              }
            }
          `,
          // Parameters
          variables: {
            id: this.school.id,
            image_url: imageUrl,
          },
        })
        .then((response) => {
          crudNotification(
            response.data.updateSchoolLogo.errors,
            'Successfully updated.'
          ).then(() => {
            // this.$router.go()
            // this.activeTab = 1
            this.school.imageUrl = imageUrl
            this.modal = false
          })
        })
        .catch((error) => {
          console.error(error)
        })
    },
    //
    initializeUpdate() {
      this.$router.push(`/school/${this.schoolId}/student/${this.student.id}`)
    },
    initializeRemove() {
      this.$store.commit('setAppModalActive', true)
      this.context = 'remove'
    },
    setOpen(index) {
      this.index = index
    },
    updateSchoolBasicInfo() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateSchoolBasicInfoQuery(
              $id: Int!
              $name: String!
              $email: String!
              $address_1: String!
              $address_2: String!
              $address_3: String!
              $phone_1: String!
              $phone_2: String!
              $classification: String!
              $abbrv: String!
            ) {
              updateSchoolBasicInfo(
                input: {
                  id: $id
                  name: $name
                  email: $email
                  address1: $address_1
                  address2: $address_2
                  address3: $address_3
                  phone1: $phone_1
                  phone2: $phone_2
                  classification: $classification
                  abbrv: $abbrv
                }
              ) {
                school {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            id: parseInt(this.schoolId),
            name: this.school.name,
            email: this.school.email,
            address_1: this.school.address1,
            address_2: this.school.address2,
            address_3: this.school.address3,
            phone_1: this.school.phone1,
            phone_2: this.school.phone2,
            classification: this.school.classification,
            abbrv: this.school.abbrv,
          },
        })
        .then((response) => {
          crudNotification(
            response.data.updateSchoolBasicInfo.errors,
            'Successfully updated.'
          )
          this.$apollo.queries.school.refetch()
        })
        .catch((error) => {
          console.error(error)
        })
    },
    updatePayStackKey() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation updatePayStackKeyQuery($id: Int!, $payStackKey: String!) {
              updatePayStackKey(input: { id: $id, payStackKey: $payStackKey }) {
                school {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            id: parseInt(this.schoolId),
            payStackKey: this.school.payStackKey,
          },
        })
        .then((response) => {
          crudNotification(
            response.data.updatePayStackKey.errors,
            'Successfully updated.'
          )
          this.$apollo.queries.school.refetch()
        })
        .catch((error) => {
          console.error(error)
        })
    },
    updateSettings() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateSettingsQuery(
              $id: Int!
              $resultAvatarEnabled: Boolean!
              $hideResultHeader: Boolean!
              $displayFee: Boolean!
            ) {
              updateSettings(
                input: {
                  id: $id
                  resultAvatarEnabled: $resultAvatarEnabled
                  hideResultHeader: $hideResultHeader
                  displayFee: $displayFee
                }
              ) {
                school {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            id: parseInt(this.schoolId),
            resultAvatarEnabled: this.school.resultAvatarEnabled,
            hideResultHeader: this.school.hideResultHeader,
            displayFee: this.school.displayFee,
          },
        })
        .then((response) => {
          crudNotification(
            response.data.updateSettings.errors,
            'Successfully updated.'
          )
          this.$apollo.queries.school.refetch()
        })
        .catch((error) => {
          console.error(error)
        })
    },
    updateGSuite() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateGSuiteQuery(
              $id: Int!
              $gSuiteDomain: String!
              $clientId: String!
              $clientSecret: String!
            ) {
              updateGSuite(
                input: {
                  id: $id
                  gSuiteDomain: $gSuiteDomain
                  clientId: $clientId
                  clientSecret: $clientSecret
                }
              ) {
                school {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            id: parseInt(this.schoolId),
            gSuiteDomain: this.school.gSuiteDomain,
            clientId: this.school.clientId,
            clientSecret: this.school.clientSecret,
          },
        })
        .then((response) => {
          crudNotification(
            response.data.updateGSuite.errors,
            'Successfully updated.'
          )
          this.$apollo.queries.school.refetch()
        })
        .catch((error) => {
          console.error(error)
        })
    },
    updateSimsedCategory() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateSimsedCategoryQuery(
              $id: Int!
              $simsedCategory: String!
              $subscriptionFee: String!
            ) {
              updateSimsedCategory(
                input: {
                  id: $id
                  simsedCategory: $simsedCategory
                  subscriptionFee: $subscriptionFee
                }
              ) {
                school {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            id: parseInt(this.schoolId),
            simsedCategory: this.school.simsedCategory,
            subscriptionFee: this.school.subscriptionFee,
          },
        })
        .then((response) => {
          crudNotification(
            response.data.updateSimsedCategory.errors,
            'Successfully updated.'
          )
          this.$apollo.queries.school.refetch()
        })
        .catch((error) => {
          console.error(error)
        })
    },
    updateAddOnModules() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateAddOnModulesQuery(
              $id: Int!
              $feesEnabled: Boolean!
              $eLearningEnabled: Boolean!
              $hrEnabled: Boolean!
              $accountingEnabled: Boolean!
            ) {
              updateAddOnModules(
                input: {
                  id: $id
                  feesEnabled: $feesEnabled
                  eLearningEnabled: $eLearningEnabled
                  hrEnabled: $hrEnabled
                  accountingEnabled: $accountingEnabled
                }
              ) {
                school {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            id: parseInt(this.schoolId),
            feesEnabled: this.school.feesEnabled,
            eLearningEnabled: this.school.eLearningEnabled,
            hrEnabled: this.school.hrEnabled,
            accountingEnabled: this.school.accountingEnabled,
          },
        })
        .then((response) => {
          crudNotification(
            response.data.updateAddOnModules.errors,
            'Successfully updated.'
          )
          this.$apollo.queries.school.refetch()
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
  components: {
    SdInput,
    SdSelect,
    ValidationObserver,
    ImageModal,
  },
  mounted() {
    this.$store.commit('setPageTitle', this.pageTitle)

    fetchUser().then((user) => {
      this.user = user
      this.schoolId = user.school_id
      this.$store.commit('setSubMenus', [
        {
          name: 'Dashboard',
          route: `/school/${this.schoolId}/dashboard`,
        },
      ])

      this.$apollo.addSmartQuery('school', {
        query: gql`
          query schoolQuery($id: ID!) {
            school(id: $id) {
              id
              abbrv
              imageUrl
              name
              email
              address1
              address2
              address3
              phone1
              phone2
              classification
              simsedCategory
              subscriptionFee
              feesEnabled
              eLearningEnabled
              gSuiteEnabled
              hrEnabled
              accountingEnabled
              resultAvatarEnabled
              hideResultHeader
              displayFee
              gSuiteDomain
              clientId
              clientSecret
              payStackKey
            }
          }
        `,
        variables: {
          id: this.schoolId,
        },
        error(error) {
          this.$buefy.notification.open({
            duration: 5000,
            message: error.message,
            position: 'is-top-right',
            type: 'is-danger',
            hasIcon: true,
          })
        },
      })
      this.$apollo.queries.school.refetch()
    })
  },
}
</script>
